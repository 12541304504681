import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const MyVerticallyCenteredModal = props => {
  const review = props.review
  //   console.log('review', review)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className="d-flex align-self-center">
          <LazyLoadImage
            className="mb-3 review-img"
            src={review?.photo}
            alt={review?.name}
          />
          <div className="align-self-center">
            <h5 className="mt-0 mb-0" style={{ color: '#d86327' }}>
              {review?.name}
            </h5>
            <h5 className="mt-0" style={{ color: '#d86327' }}>
              {review?.address}
            </h5>
          </div>
        </div>{' '}
      </Modal.Header>
      <Modal.Body>
        <i className="fas fa-quote-left" /> {review.review}{' '}
        <i className="fas fa-quote-right" />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-global modal-btn" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MyVerticallyCenteredModal
