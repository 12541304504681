import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import MySwiper from '../components/Swiper'
// import MySwiper2 from '../components/Swiper2'
import MySwiper3 from '../components/swiper3'
import 'react-lazy-load-image-component/src/effects/blur.css'
// import ContactForm from '../components/contact'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Helmet } from 'react-helmet'
import MyVerticallyCenteredModal from '../components/CustomModal'

/*Images*/

import { LOGO1, LOGO2, LOGO3 } from '../assets/images'
import fb from '../assets/images/fb.svg'
import bbb from '../assets/images/bbb.svg'
import yelp from '../assets/images/yelp.svg'
import client1 from '../assets/images/client-1.jpg'
import client2 from '../assets/images/client-2.jpeg'
import client3 from '../assets/images/client-3.jpg'
import client4 from '../assets/images/client-4.jpg'
import client5 from '../assets/images/client-5.jpg'
import aboutImg from '../assets/images/about-us.png'
import logoName from '../assets/images/logo-name.png'
// import logo1 from '../assets/images/logo1.jpg'
// import logo2 from '../assets/images/logo2.png'
// import logo3 from '../assets/images/logo3.jpg'
import logo4 from '../assets/images/logo4.jpg'
import logo5 from '../assets/images/logo5.jpg'
import logo6 from '../assets/images/logo6.jpg'
import gate1 from '../assets/images/gate1.jpg'
import gate2 from '../assets/images/gate2.jpg'
import gate3 from '../assets/images/gate3.jpg'
import gate4 from '../assets/images/gate4.png'
import gate5 from '../assets/images/gate5.jpg'
import gate6 from '../assets/images/gate6.jpg'

const gates = [
  {
    img: gate1,
    label: 'Arched Gates',
    link: '/gates/gate-designs/arched-gates',
  },
  { img: gate2, label: 'Bell Gates', link: '/gates/gate-designs/bell-gates' },
  {
    img: gate3,
    label: 'Flat Top Gates',
    link: '/gates/gate-designs/flat-top-gates',
  },
  {
    img: gate4,
    label: 'Custom Gates',
    link: '/gates/gate-designs/custom-gate-designs',
  },
  {
    img: gate5,
    label: 'Swinging Gates',
    link: '/gates/gate-types/swing-gates',
  },
  {
    img: gate6,
    label: 'Sliding Gates',
    link: '/gates/gate-types/sliding-gates',
  },
]

const reviews = [
  {
    review: `I appreciate that
    after my previous bad experience Vickie and the team at
    J & J Garage & Gate Services made every effort to
    correct the situation and get our internet gateway
    installed. We are very happy now and wouldn't hesitate
    to recommend them or use them in the future. Thank you
    for your help and making sure we ended up happy.`,
    photo: client1,
    name: 'Elizabeth Kisha',
    address: 'Arlington, TX',
  },
  {
    review: `I called JJ gates
    inquiring about building a large gate for my guest
    house, he was very professional on the phone and
    extremely punctual. I can respect that. His company
    built a gate above and beyond my expectations in a few
    days. Same day install with a crew of clean intuitive
    employees. If you want a gate installed correctly with a
    knowledgeable staff, Call JJ gates. They even know how
    to hook the gates up to your phone so you can control
    them from anywhere, know when it's open or closed at
    what times and when. That is great. Thank you guys
    again.`,
    photo: client2,
    name: 'Jerry S.',
    address: 'Arlington, TX',
  },
  {
    review: `I first used this
    company 2 years ago. The company who installed my solar
    gate went out of business and I had to find someone to
    come out and adjust my gate and add puppy bars. I just
    went on line and googled and read reviews. They came out
    and fixed my problem. I just had to have them back out
    to fix another issue. I was thinking it was not going to
    be something easily fixed so they were sending someone
    out to give me a quote on repair. It turned out he was
    able to make an adjustment and fix it right then. Even
    though I have only used them twice they have been very
    courteous and out here when they say they will be and
    this time he called and said he could make it earlier. I
    would definetly recommend this company.`,
    photo: client3,
    name: 'Kathy M',
    address: 'Arlington, TX',
  },
  {
    review: `Reliable, courteous, and quality work. Repeat customer.`,
    photo: client4,
    name: 'Pat Krapf',
    address: 'Dallas, TX',
  },
  {
    review: `Got my gate
    operating again, after the upper hinge broke. Replaced
    both upper & lower hinges. It was refreshing to find a
    company that would actually come to Farmersville in
    Collin County.`,
    photo: client5,
    name: 'John C. Thomas',
    address: 'Farmersville, Texas',
  },
]

const IndexPage = ({ data, location }) => {
  // console.log(location)
  const [modalShow, setModalShow] = useState(false)
  const [modalItem, setModalItem] = useState([])
  // const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description

  const CollapseModal = name => {
    // console.log('name', name)
    setModalShow(!modalShow)
    reviews &&
      reviews.filter(item => {
        if (name === item?.name) {
          setModalItem(item)
        }
      })
  }
  // console.log('moda/lShow', modalShow)
  return (
    <Layout location={location}>
      <MyVerticallyCenteredModal
        review={modalItem}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <SEO
        title="J&J Automatic Service & Design - Gate Installation, Repair, & Design"
        description={siteDescription}
      />
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-88780342-1"
        />
      </Helmet>
      <div className="container-fluid pr-0 pl-0 hero-container" id="top">
        <div className="container-fluid hero-section" />
        <MySwiper slidesperview={1}>
          <div className="jumbotron slide-1" />
          <div className="jumbotron slide-2" />
          <div className="jumbotron slide-3" />
        </MySwiper>
        <div className="col-md-8 offset-md-2 text-center text-white brandstamp">
          <LazyLoadImage
            className="brandstamp-logo mb-3"
            src={logoName}
            alt="JJ Gates"
          />
          <h4 className="mt-0 mb-0">AUTOMATIC GATE INSTALLATION</h4>
          <h4 className="mt-0 mb-0">GATE REPAIR & DESIGN</h4>
          <h1 className="mt-0">J&J AUTOMATIC GATE SERVICE & DESIGN</h1>
          <hr />
        </div>
      </div>

      <div className="container-fluid locations text-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center py-4 twoCTAs">
              <Link to="/contact-us" className="btn-global mx-2">
                <i className="fas fa-dollar mr-2" />
                We now Offer Financing!
              </Link>
              <Link to="/contact-us" className="btn-global mx-2">
                <i className="fas fa-calculator" />
                Get Free Estimate
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid gates-section">
        <div className="container text-center">
          <h4 className="mt-0">Gate Designs</h4>
          <hr />
          <p className="mb-5">
            No matter which style of gate you’re looking for, J & J Gates
            Service and Design has the perfect option for you! Do you have
            limited space? Want something a little more decorative and unique?
          </p>
          <div className="row">
            {' '}
            {gates.map(gate => (
              <div className="col-md-4 ">
                <Link to={gate.link}>
                  <div
                    className="img-thumbnail shadow my-3 pt-3 px-0 pb-0"
                    style={{ overflow: 'hidden' }}
                  >
                    <LazyLoadImage
                      className="w-100 "
                      src={gate.img}
                      alt="Bell Gate"
                    />
                    <div style={{ background: '#d86327' }}>
                      <h5 className="mt-3 py-2 text-white mb-0">
                        {gate.label}
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container-fluid value-props pt-0">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4 text-left">
              <h4 className="primary-color mb-0 mt-0">
                Premier Gate Services in Dallas &amp; Fort Worth, TX
              </h4>
              <hr />
              <h5 className="mt-0 mb-5 vp-heading-2">
                Why Choose Us?
                <br />
              </h5>
            </div>
            <div className="col-md-8 text-left">
              <p>
                When it comes to choosing a gate contractor, do not waste your
                time and money on one that is less-than-stellar. We have been in
                the business of providing excellent gate installation and repair
                services for residential and commercial gates for more than 12
                years.
              </p>
              <div className="row">
                <div className="col-lg-4">
                  <p>
                    We specialize in accurately installing gate operators and
                    telephone entry systems - includes safety and fire
                    equipment.
                    <br />
                  </p>
                </div>
                <div className="col-lg-4">
                  <p>
                    We professionally install gates, operators and access
                    systems according to our clients needs. We custom build a
                    system for specific needs not cookie cutter packages that
                    everyone buys.
                    <br />
                  </p>
                </div>
                <div className="col-lg-4">
                  <p>
                    We are available and ready to repair, replace or install.
                    The chat option on the website is the biggest call to action
                    we have.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid about-us">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-4 offset-md-2 text-center about-img-container">
              <LazyLoadImage
                className="about-img mt-3"
                src={aboutImg}
                alt="About"
              />
            </div>

            <div className="col-md-4 about-content pl-0">
              <h4>About Us</h4>
              <hr />
              <p className="mb-5">
                We repair, replace and install new access gates, operators,
                keypads, call boxes, loop detectors and knox fire safety
                equipment. We provide free estimates.
              </p>
              <Link className="btn-global mt-5" to="/about-us">
                About Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid logo-farm">
        <div className="container  text-center">
          <div className="row">
            <div className="col-md-12">
              <a
                href="https://www.liftmaster.com/"
                target="_blank"
                rel="nofollow noreferrer"
                title="Lift Master"
              >
                <LazyLoadImage src={LOGO2} alt="Lift Master" />
              </a>
              <a
                href="https://eagleoperators.com/"
                target="_blank"
                rel="nofollow noreferrer"
                title="Eagle Operators"
              >
                <LazyLoadImage src={LOGO3} alt="Eagle Access" />
              </a>
              <a
                href="https://www.usautomatic.com/"
                target="_blank"
                rel="nofollow noreferrer"
                title="USA Automatic"
              >
                <LazyLoadImage src={LOGO1} alt="USAUtomatic" />
              </a>
            </div>
            <div className="col-md-12">
              <a
                href="http://www.niceforyou.us/"
                target="_blank"
                rel="nofollow noreferrer"
                title="Nice for you"
              >
                <LazyLoadImage src={logo4} alt="Apollo Gates" />
              </a>
              <a
                href="https://elitegates.net/"
                target="_blank"
                rel="nofollow noreferrer"
                title="Eagle Gates"
              >
                <LazyLoadImage src={logo5} alt="Elite Gates" />
              </a>
              <a
                href="https://www.vikingaccess.com/"
                target="_blank"
                rel="nofollow noreferrer"
                title="Viking Access"
              >
                <LazyLoadImage src={logo6} alt="Viking" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid alu-section px-3">
        <div className="container">
          <div className="row alu-row">
            <div className="col-md-8 ">
              <h4 className="mt-0 mb-0">
                Cost-Effective{' '}
                <span className="primary-color">Aluminum Gates</span> for
                Dallas, Arlington, & Fort Worth Businesses
              </h4>
              <hr />
              <h6 className="mt-0">
                Aluminum gates have gained a ton of popularity in the commercial
                market for some time now. Why do businesses love them so much?
              </h6>
            </div>
            <div className="col-md-4 text-center">
              <Link to="/gates/gate-types/aluminum" className="btn-global">
                Aluminum Gates
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid svc-section text-center">
        <div>
          <div className="svc-inner">
            <div className="row">
              <div className="col-md-12 svc-1">
                <div className="svc-content">
                  <h5 className="mt-0">
                    Gate Builds and Gate Operator Installs
                  </h5>
                  <hr />
                  <p className="mb-5">
                    We custom build gates for commercial and residential
                    properties. We also automate them to make them access
                    systems
                  </p>
                  <Link
                    className="btn-global inverted"
                    to="/gates/services/gate-automation"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="overlay-1" />
                <h5 className="hover-info text-white">HOVER TO LEARN MORE</h5>
                <h5 className="touch-info text-white">TOUCH TO LEARN MORE</h5>
              </div>
              <div className="col-md-6 svc-2">
                <div className="svc-content">
                  <h5 className="mt-0">Access Systems</h5>
                  <hr />
                  <p className="mb-5">
                    Adding a call box or keypad, exit loops, safety loops,
                    everything needed for an automated access system
                  </p>
                  <Link
                    className="btn-global inverted"
                    to="/gates/services/access-systems"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="overlay-1" />
                <h5 className="hover-info text-white">HOVER TO LEARN MORE</h5>
                <h5 className="touch-info text-white">TOUCH TO LEARN MORE</h5>
              </div>

              <div className="col-md-6 svc-3">
                <div className="svc-content">
                  <h5 className="mt-0">Replacement Parts and Repairs</h5>
                  <hr />
                  <p className="mb-5">
                    We service and repair all types of gates, openers, and
                    access systems.
                  </p>
                  <Link
                    className="btn-global inverted"
                    to="/gates/services/gate-repair"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="overlay-1" />
                <h5 className="hover-info text-white">HOVER TO LEARN MORE</h5>
                <h5 className="touch-info text-white">TOUCH TO LEARN MORE</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid review-section">
        <div className="container inner-div">
          <div className="row">
            <div className="col-md-5 p-3 text-white">
              <h4 className="mt-0">Reviews</h4>
              <hr />
              <div className="row">
                <div className="p-0 review-icon-container">
                  <div className="reviewsocial d-flex justify-content-start align-items-center mb-3">
                    <LazyLoadImage
                      className="review-icons mb-0 mr-3"
                      src={fb}
                      alt="Facebook"
                    />
                    <a
                      href="https://www.facebook.com/pg/JJAutomaticGateServiceDesign/reviews/"
                      target="_blank"
                      className="review-link"
                      rel="noopener noreferrer"
                    >
                      Review us on Facebook!
                    </a>
                  </div>
                  <div className="reviewsocial d-flex justify-content-start align-items-center mb-3">
                    <LazyLoadImage
                      className="review-icons mb-0 mr-3"
                      src={yelp}
                      alt="Yelp"
                    />
                    <a
                      href="https://www.yelp.com/biz/j-and-j-automatic-gate-service-and-design-dallas"
                      target="_blank"
                      className="review-link"
                      rel="noopener noreferrer"
                    >
                      Review us on Yelp!
                    </a>
                  </div>
                  <div className="reviewsocial d-flex justify-content-start align-items-center mb-3">
                    <LazyLoadImage
                      className="review-icons mb-0 mr-3"
                      src={bbb}
                      alt="Better Business Bureau"
                    />
                    <a
                      href="https://www.bbb.org/us/tx/arlington/profile/door-and-gate-operating-devices/j-j-gates-service-and-design-0825-235965056"
                      target="_blank"
                      className="review-link"
                      rel="noopener noreferrer"
                    >
                      Review us on BBB!
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 review-swiper-wrap">
              <MySwiper3 slidesperview={1}>
                {reviews &&
                  reviews.map((review, idx) => (
                    <div className="px-2">
                      <div className="review-slide slide-2">
                        <p className="">
                          <i className="fas fa-quote-left" />{' '}
                          {review.review.length >= 100
                            ? review.review.substring(0, 100)
                            : review.review}
                          <>
                            {review.review.length >= 100 ? (
                              <button
                                className="bg-transparent"
                                value={review?.name}
                                onClick={() => CollapseModal(review?.name)}
                              >
                                <i> ...see more</i>
                              </button>
                            ) : null}
                          </>{' '}
                          <i className="fas fa-quote-right" />
                        </p>
                        <div className="row">
                          <div className="col-lg-2">
                            <LazyLoadImage
                              className="mb-3 review-img"
                              src={review?.photo}
                              alt={review?.name}
                            />
                          </div>
                          <div className="col-lg-9">
                            <h5 className="mt-0 mb-0">{review?.name}</h5>
                            <h5 className="mt-0">{review?.address}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </MySwiper3>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
